<template>
  <div class="layout-default">
    Empty Project is running
    <slot />
  </div>
</template>

<script setup>
</script>
<style lang="scss" scoped>
.layout-default {
    padding-top: calc(var(--header-height) + 60px);
}
</style>
